<template>
  <el-container class="mine">
    <div class="container">
      <div class="mine-route">
        <router-link to="/">首页</router-link>
        <router-link to="/mine/order">
          <el-icon>
            <ArrowRight />
          </el-icon>
          个人中心</router-link>
        <a href="javascript:;">
          <el-icon>
            <ArrowRight />
          </el-icon>
          {{ route.meta.title }}</a>
      </div>
      <el-row class="mine-row">
        <el-col v-show="listShow.includes(route.name)" :span="4">
          <el-card class="mine-info">
            <div class="mine-info-inner">
              <div class="mine-info-avatar">
                <el-avatar shape="square" :src="info.avatar"/>
              </div>
              <div class="mine-info-name">
                {{ info.name ? info.name : info.nickname }}
              </div>
              <div class="mine-info-line"></div>
            </div>
            <div class="mine-list">
              <router-link v-for="({ name, meta }, idx) in lists" :key="idx" :to="{ name: name }"
                :class="{ active: active == name }">{{ meta.title }}</router-link>
            </div>
          </el-card>
        </el-col>
        <el-col :span="listShow.includes(route.name) ? 20 : 24" class="mine-content">
          <router-view class="mine-router-view" />
        </el-col>
      </el-row>
    </div>
  </el-container>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import _ from 'lodash'
import { routes } from '@/router/list.js'
import { ArrowRight, UserFilled } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
const route = useRoute()
const store = useStore()
const info = computed(() => {
  return store.getters?.account_info
})
const datas = _.keyBy(routes[1]['children'], 'name')['mine']['children']
const lists = computed(() => {
  return _.compact(
    datas &&
    datas.length &&
    datas.map((n) => {
      if (!n.hidden) {
        return n
      }
    })
  )
})
const listShow = computed(() => {
  return _.compact(
    datas &&
    datas.length &&
    datas.map((n) => {
      if (!n.hidden) {
        return n.name
      }
    })
  )
})
const active = ref('')
watch(
  route,
  (e) => {
    active.value = e.name.value
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.mine {
  min-height: 88vh;

  &-row {
    margin-bottom: 100px;
  }

  &-info {
    min-height: 800px;
    padding-top: 30px;

    &-avatar {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      border: 1px solid rgba(#999999, 0.6);
      margin-bottom: 15px;
      border-radius: 3px;
      overflow: hidden;

      .el-icon {
        width: 100%;
        height: 100%;
        font-size: 100px;
      }

      .el-avatar {
        width: 100%;
        height: 100%;
      }
    }

    &-name {
      font-size: 20px;
      text-align: center;
      padding: 0 15px;
    }

    &-line {
      border-bottom: 1px solid var(--el-border-color);
      margin-left: -20px;
      margin-right: -20px;
      padding-top: 20px;
      margin-bottom: 20px;
    }
  }

  &-route {
    display: flex;
    padding-top: 25px;
    padding-bottom: 20px;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      color: #999;

      &:first-of-type {
        color: #333;
        font-weight: bold;
      }
    }

    // font-size: 16px;
  }

  &-content {
    padding-left: 15px;
  }

  &-router-view {
    min-height: 800px;
  }

  &-list {
    a {
      display: block;
      color: #333;
      font-size: 20px;
      padding: 15px 30px 15px 50px;
      margin-left: -20px;
      margin-right: -20px;

      &:hover {
        background-color: rgba(#999999, 0.1);
      }

      &.router-link-active {
        color: var(--el-color-primary);
        position: relative;

        &::before {
          content: '';
          width: 4px;
          height: 4px;
          background-color: var(--el-color-primary);
          position: absolute;
          left: 30px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}
</style>
